import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import Input from '@salesforce/design-system-react/lib/components/input';
import Checkbox from '@salesforce/design-system-react/lib/components/checkbox';

import { PaymentMethodContact } from '../types';

function NameFields({ firstName, lastName, onChange }: any) {
  const handleChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) =>
      onChange(field, event.currentTarget.value);

  return (
    <div className="slds-form__row slds-grid">
      <div className="slds-col_padded">
        <Input
          label="First Name"
          value={firstName}
          onChange={handleChange('firstName')}
        />
      </div>
      <div className="slds-col_padded">
        <Input
          label="Last Name"
          value={lastName}
          onChange={handleChange('lastName')}
        />
      </div>
    </div>
  );
}

function EmailField({ email, onChange }: any) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange('email', event.currentTarget.value);

  return (
    <div className="slds-form__row slds-grid">
      <div className="slds-col_padded">
        <Input label="Email" value={email} onChange={handleChange} />
      </div>
    </div>
  );
}

function AddressFields({
  street,
  city,
  state,
  postalCode,
  country,
  onChange,
}: any) {
  const handleChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) =>
      onChange(field, event.currentTarget.value);

  return (
    <>
      <div className="slds-form__row slds-grid">
        <div className="slds-col_padded">
          <Input
            label="Street"
            value={street}
            onChange={handleChange('street')}
          />
        </div>
      </div>
      <div className="slds-form__row slds-grid">
        <div className="slds-col_padded slds-size_3-of-6">
          <Input label="City" value={city} onChange={handleChange('city')} />
        </div>
        <div className="slds-col_padded slds-size_3-of-6">
          <Input
            label="State/Province"
            value={state}
            onChange={handleChange('state')}
          />
        </div>
      </div>
      <div className="slds-form__row slds-grid">
        <div className="slds-col_padded slds-size_4-of-7">
          <Input
            label="Postal Code"
            value={postalCode}
            onChange={handleChange('postalCode')}
          />
        </div>
        <div className="slds-col_padded slds-size_3-of-7">
          <Input
            label="Country"
            value={country}
            onChange={handleChange('country')}
          />
        </div>
      </div>
    </>
  );
}

interface BillingDetailsExtra {
  copyPersonal?: boolean;
}

interface Props {
  defaultDetails?: PaymentMethodContact & BillingDetailsExtra;
  details?: PaymentMethodContact & BillingDetailsExtra;
  onChange: (payload: any) => void;
  isBillingContact?: boolean;
}

const initialState: any = {
  type: 'personal',
  firstName: '',
  lastName: '',
  email: '',
  state: '',
  street: '',
  city: '',
  postalCode: '',
  country: '',
  copyPersonal: false,
};

export default function BillingForm({
  isBillingContact = false,
  defaultDetails,
  details,
  onChange,
}: Props) {
  const [state, setState] = useState(details || initialState);

  useEffect(() => {
    setState(details || initialState);
  }, [isBillingContact]);

  const debouncedOnChange = debounce(onChange, 300);
  const handleChange = useCallback(
    (key: string, value: any) => {
      const updatedState = {
        ...state,
        [key]: value,
      };

      setState(updatedState);
      debouncedOnChange(updatedState);
    },
    [state, debouncedOnChange]
  );
  const handleCopyPersonalCheck = (event: any, { checked }: any) => {
    let updatedState: any = {
      copyPersonal: checked,
    };

    if (checked) {
      updatedState = {
        ...updatedState,
        ...defaultDetails,
      };
    }

    setState(updatedState);
    onChange(updatedState);
  };

  return (
    <div className="slds-form slds-m-vertical_medium">
      {isBillingContact && (
        <Checkbox
          checked={state.copyPersonal}
          className="slds-m-top_xx-small slds-m-bottom_small"
          labels={{ label: 'Billing contact same as Personal information' }}
          onChange={handleCopyPersonalCheck}
        />
      )}
      <NameFields
        firstName={state.firstName}
        lastName={state.lastName}
        onChange={handleChange}
      />
      <EmailField email={state.email} onChange={handleChange} />
      <AddressFields
        street={state.street}
        city={state.city}
        state={state.state}
        postalCode={state.postalCode}
        country={state.country}
        onChange={handleChange}
      />
    </div>
  );
}
