import { schema } from 'normalizr';

const decodeHTMLEntities = (text: string) => {
  let textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
}

const plugin = new schema.Entity(
  'plugins',
  {},
  {
    processStrategy(value, _parent, _key) {
      return {
        ...value,
        data: decodeHTMLEntities(value.data),
      };
    },
    idAttribute: (plugin) => plugin.name || plugin.itemId,
  }
);

export default plugin;
